<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small"
						@click="config.isDetailDisplay = false">返回</el-button> </p>
				<div class="h-b"><el-button type="primary" size="small" @click="saveApi"
						v-if="dataSource.Id!=null" v-right-code="'Modelinfo:Edit'">保存</el-button> </div>
				<div class="h-b"><el-button type="primary" size="small" @click="saveApi"
						v-if="dataSource.Id==null" v-right-code="'Modelinfo:Create'">保存</el-button>
				</div>
			</div>
		</div>

		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane name="tabbasic">
				<span slot="label">基本信息</span>
				<el-form ref="_editForm" :model="dataSource" >
					<el-collapse v-m3odel.trim="formItems">
						<el-collapse-item title="基本信息" name="formItem">
							<el-form-item>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>编码：</el-col>
								<el-col :span="5">
									<el-form-item prop="Code">
										<el-input v-model.trim="dataSource.Code" :maxlength="30" :minlength="4"
											:disabled="true" :readonly="true" placeholder="系统自动生成"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="3" class="form-title"><span style="color:red;">*</span>类型：</el-col>
								<el-col :span="5">
									<el-form-item prop="AuxiliaryMaterialType">
										<el-select v-model="dataSource.AuxiliaryMaterialType" placeholder="请选择">
											<el-option v-for="item in typeList" :key="item.Key" :label="item.Value" :value="item.Key">
											</el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item>						
								<el-col :span="3" class="form-title"> <span style="color:red;">*</span>型号：</el-col>
								<el-col :span="5">
									<el-form-item prop="Name">
									<el-input v-model.trim="dataSource.Name"  placeholder="型号"></el-input>
									</el-form-item>
								</el-col>
							</el-form-item>
						</el-collapse-item>
					</el-collapse>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	export default {
		mounted() {


		},
		data() {
			return {
				isDisabled: false,
				activeName: "tabbasic",
				formItems: ['formItem'],
				typeList: [{
						Key: 1,
						Value: "辅材"
				
					},
					{
						Key: 5,
						Value: "保温箱"
				
					},
					{
						Key: 10,
						Value: "冰排"
				
					},
					{
						Key: 15,
						Value: "温度计"
					}
				],
		
			};
		},
		props: {
			config: {
				isDetailDisplay: false,
				dataSourceKey: null,
			},
			dataSource: {


			}
		},
		watch: {
			'config.dataSourceKey': {
				handler(curVal, oldVal) {
					if (curVal && curVal > 0) {
						if (curVal != oldVal) this.getDetail(curVal); //初始化
					}
				},
				deep: true
			},
		},
		methods: {

			add() {
				var _this = this;
				this.$ajax.send("omsapi/modelinfo/getempty", "get", {}, (data) => {
					_this.dataSource = data.Result;
				});
			},
			editApi() {
				this.config.isAdd = true;
			},
			detailApi(apiId) {
				this.config.isAdd = false;
			},
			getDetail(apiId) {
				var _this = this;
				this.$ajax.send("omsapi/modelinfo/get", "get", {
					id: apiId
				}, (data) => {
					_this.dataSource = data.Result;
				});
			},
			saveApi() {
				this.$refs["_editForm"].validate((valid) => {
					if (valid) {
						var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
						var obj = this.Utils.cloneObj(this.dataSource);
						this.$ajax.send("omsapi/modelinfo/" + actionName, "post", obj, (data) => {
							this.config.dataSourceKey = data.Result;
							this.config.isCanEdit = false;
							this.config.isAdd = false;
							this.Event.$emit("reloadRolePageList");
							this.Utils.messageBox("保存成功.", "success");
						});
					} else {
						this.Utils.messageBox("存在必填信息未输入.", "error");
						return false;
					}
				});
			},
		},
		components: {
		//异常记录

		}
	}
</script>